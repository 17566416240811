import {Link} from "react-router-dom"

import notFound from "../../assets/icons/member/notFound.svg"
import {ReactComponent as User} from "../../assets/icons/sidebar/user.svg"
import classes from "./index.module.scss"

const NotFound = () => {
  return (<div className={classes.container}>
    <div className={classes.block}>
      <img src={notFound} alt="notFound"/>
      <h2>Page not found</h2>
      <span>We apologize for the inconvenience. <br/>
        It appears the page you're looking for is off the charts.
      </span>
      <Link to="/devs">
        <User/>
        Go to Developers
      </Link>
     </div>
  </div>)
}

export default NotFound
