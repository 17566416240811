import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {URL} from "../../constants";

export const integrationsApi = createApi({
  reducerPath: "integrations/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState()).auth.token;
      if (token) {
        headers.set("accept", "application/json")
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers;
    }
  }),
  tagTypes: ["Integrations"],
  endpoints: (build) => ({
    getIntegrations: build.query({
      query: () => "integrations",
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Integration', id })),
              { type: 'Integration', id: 'LIST' },
            ]
          : [{ type: 'Integration', id: 'LIST' }],
    }),
    getIntegration: build.query({
      query: (id) => `integrations/${id}`,
      providesTags: (result, error, id) => [{ type: "Integration", id }]
    }),
    updateService: build.mutation({
      query: ({id, serviceId, ...patch}) => ({
        url: `integrations/${id}/services/${serviceId}`,
        method: "PUT",
        body: patch
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Integration', id }],
    }),
    deleteService: build.mutation({
      query: ({id, serviceId}) => ({
        url: `integrations/${id}/services/${serviceId}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Integration', id }],
    })
  })
})

export const {
  useGetIntegrationsQuery,
  useGetIntegrationQuery,
  useUpdateServiceMutation,
  useDeleteServiceMutation
} = integrationsApi
