import {RouterProvider} from "react-router-dom"
import { Suspense } from "react"
import {ToastContainer, Flip} from "react-toastify"

import router from "./router"
import Loader from "./components/Loader"
import 'react-toastify/dist/ReactToastify.css'
import 'react-tooltip/dist/react-tooltip.css'
import "./App.css"

function App() {
  const renderLoader = () => <Loader/>;

  return (
    <Suspense fallback={renderLoader()}>
      <RouterProvider router={router()} />
      <ToastContainer autoClose={2000} hideProgressBar transition={Flip}/>
    </Suspense>
  );
}

export default App;
