import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userId: "",
  token: null,
  role: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { id, accessToken, role } }
    ) => {
      state.userId = id;
      state.token = accessToken;
      state.role = role;
    },
    removeCredentials: (state) => {
      state.userId = null;
      state.token = null;
      state.role = null;
    }
  },
  extraReducers: (builder) => {}
})

export const { setCredentials, removeCredentials } = authSlice.actions;

export default authSlice.reducer;
export const authActions = authSlice.actions

export const selectCurrentUser = (state) => state.auth.userId;
export const selectCurrentToken = (state) => state.auth.token;
