import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
  useRouteError
} from "react-router-dom"
import {lazy} from "react"

import NotFound from "./pages/NotFound"

const Layout = lazy(() => import("./components/Layout"))
const Members = lazy(() => import("./pages/Members"))
const Member = lazy(() => import("./pages/Member"))
const Settings = lazy(() => import("./pages/Settings"))
const Integrations = lazy(() => import("./pages/Integrations"))
const Github = lazy(() => import("./pages/Github"))
const Jira = lazy(() => import("./pages/Jira"))
const Calendar = lazy(() => import("./pages/Calendar"))

function ErrorBoundary() {
  let error = useRouteError()
  //
  // if(isRouteErrorResponse(error) && error.status === 401) {
  //   return <Login/>;
  // }
  if (error.status === 404) {
    return <NotFound />
  }

  return <div>Path is not defined</div>
}

export default function Router() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<ErrorBoundary />}>
        <Route index loader={() => redirect("/devs")} />

        <Route
          path="/invitation"
          element={<></>}
          loader={() => {
            localStorage.removeItem("id")
            localStorage.removeItem("token")
            localStorage.removeItem("role")
            return <></>
          }}
        />

        <Route path=":memberId" element={<Member />} />

        <Route path="/devs" element={<Members />} />

        <Route path="/non-devs" element={<Members />} />

        <Route path="/settings" element={<Settings />} />

        <Route path="/settings/integrations" element={<Integrations />} />

        <Route path="/settings/integrations/github" element={<Github />} />

        <Route path="/settings/integrations/jira" element={<Jira />} />

        <Route path="/settings/integrations/calendar" element={<Calendar />} />
      </Route>
    )
  )
}
