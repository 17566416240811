import { isRejectedWithValue } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {removeCredentials} from "../features/auth/authSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if(action.payload.originalStatus === 400) {
      return next(action)
    }
    toast.error(action.payload.data.message || action.payload.data)
    if(action.payload.status === 401) {
      localStorage.removeItem('id');
      localStorage.removeItem('token');
      localStorage.removeItem('role');

      api.dispatch(removeCredentials())
    }
  }

  return next(action)
}
