import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {URL} from "../../constants"

export const usersApi = createApi({
  reducerPath: "users/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}`,
    prepareHeaders: (headers, {getState}) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token
      if (token) {
        headers.set("accept", "application/json")
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ["Users"],
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: () => `me`,
      providesTags: ["Users"],
      transformResponse(baseQueryReturnValue, meta, arg) {
        // return {
        //   ...baseQueryReturnValue,
        //   services: [
        //     ...baseQueryReturnValue.services.map((s) =>
        //       s.name === "github" || s.name === "jira" || s.name === "gcalendar"
        //         ? {
        //             ...s,
        //             status: "up_to_date"
        //           }
        //         : s
        //     )
        //   ]
        // }
        // return {
        //   ...baseQueryReturnValue,
        //   services: [
        //     ...baseQueryReturnValue.services.map((s) =>
        //       s.name === "jira" || s.name === "gcalendar"
        //         ? {
        //             ...s,
        //             status: "not_initialized"
        //           }
        //         : s.name === "github"
        //         ? {...s, status: "in_progress"}
        //         : s
        //     )
        //   ]
        // }
        return baseQueryReturnValue
      }
    })
  })
})

export const {useGetUserInfoQuery} = usersApi
