import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {URL} from "../../constants";

export const membersApi = createApi({
  reducerPath: "members/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState()).auth.token;
      if (token) {
        headers.set("accept", "application/json")
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers;
    }
  }),
  tagTypes: ['Member'],
  endpoints: (build) => ({
    getMembers: build.query({
      query: (search) => search ? `members?search=${search}` : "members",
      providesTags: (result) =>
        result
          ? [
              ...result.developers.map(({ id }) => ({ type: 'Member', id })),
              ...result.nonDevelopers.map(({ id }) => ({ type: 'Member', id })),
              { type: 'Member', id: 'LIST' },
            ]
          : [{ type: 'Member', id: 'LIST' }],
    }),
    getMember: build.query({
      query: (id) => `members/${id}`,
      providesTags: (result, error, id) => [{ type: 'Member', id }],
    }),
    updateMember: build.mutation({
      query: ({ id, ...patch }) => ({
        url: `members/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          membersApi.util.updateQueryData('getMembers', id, (draft) => {
            Object.assign(draft, patch)
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Member', id }],
    }),
  }),
})

export const {
  useGetMembersQuery,
  useGetMemberQuery,
  useUpdateMemberMutation,
} = membersApi
