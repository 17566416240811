export const URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "https://dev.api.firbo.io/"

export const calendarVariants = [
  {
    value: "week",
    label: "Last 7 days"
  },
  {
    value: "year",
    label: "Last 12 months"
  }
]
