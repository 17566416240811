import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {URL} from "../../constants"

export const authApi = createApi({
  reducerPath: "auth/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}auth/`,
  }),
  endpoints: build => ({
    login: build.mutation({
      query: (body) => ({
        url: "login",
        method: "POST",
        body
      })
    })
  })
})

export const { useLoginMutation } = authApi
