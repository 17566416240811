import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {URL} from "../../constants";

export const chartsApi = createApi({
  reducerPath: "charts/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}members/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState()).auth.token;
      if (token) {
        headers.set("accept", "application/json")
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    getReportChart: build.query({
      query: ({id, period}) => `${id}/report?period=${period}`,
    }),
    getGithubChart: build.query({
      query: ({id, period}) => `${id}/github?period=${period}`,
    }),
    getJiraChart: build.query({
      query: ({id, period}) => `${id}/jira?period=${period}`
    }),
    getGCalendarChart: build.query({
      query: ({id, period}) => `${id}/gcalendar?period=${period}`
    }),
  })
})

export const {
  useGetReportChartQuery,
  useGetGithubChartQuery,
  useGetJiraChartQuery,
  useGetGCalendarChartQuery,
} = chartsApi
