import {Audio} from "react-loader-spinner"

const Loader = () => {
  return (
    <Audio
      height="100"
      width="100"
      color="#82C43C"
      ariaLabel="audio-loading"
      visible={true}
      wrapperStyle={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", zIndex: "999"}}
    />
  )
}

export default Loader
