import {configureStore} from "@reduxjs/toolkit"
import {authApi} from "../services/auth/authApi"
import auth from "../features/auth/authSlice"
import {membersApi} from "../services/members/membersApi"
import {integrationsApi} from "../services/integrations/integrationsApi"
import {invitationsApi} from "../services/invitations/invitationsApi"
import {usersApi} from "../services/users/usersApi"
import {chartsApi} from "../services/charts/chartsApi"
import {rtkQueryErrorLogger} from "./errorCatchMiddleware"

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    auth,
    [membersApi.reducerPath]: membersApi.reducer,
    [chartsApi.reducerPath]: chartsApi.reducer,
    [integrationsApi.reducerPath]: integrationsApi.reducer,
    [invitationsApi.reducerPath]: invitationsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(membersApi.middleware)
      .concat(chartsApi.middleware)
      .concat(integrationsApi.middleware)
      .concat(invitationsApi.middleware)
      .concat(usersApi.middleware)
      .concat(rtkQueryErrorLogger)
})

export default store
