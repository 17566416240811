import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react"
import {URL} from "../../constants"

export const invitationsApi = createApi({
  reducerPath: "invitations/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}`,
    prepareHeaders: (headers, {getState}) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token
      if (token) {
        headers.set("accept", "application/json")
        headers.set("Authorization", `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ["Invitations"],
  endpoints: (build) => ({
    getInvitation: build.query({
      query: (code) => `invitation?code=${code}`
    }),
    postInvitation: build.mutation({
      query: ({code, ...patch}) => ({
        url: `invitation?code=${code}`,
        method: "POST",
        body: patch
      })
    })
  })
})

export const {useGetInvitationQuery, usePostInvitationMutation} = invitationsApi
